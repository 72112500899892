import { graphql, useStaticQuery } from 'gatsby';

type ContentType = {
    tableOfContents: {
        items: {
            title: string;
            url: string;
        }[];
    };
    fields: {
        slug: string;
    };
    frontmatter: {
        title: string;
    };
}

export const getAllPosts = () => {
    const data = useStaticQuery(graphql`
    query GetAllPostsHandbook {
      allMdx(sort: {fields: frontmatter___order}, filter: {fileAbsolutePath: {regex: "/(handbook/parent-student)/"}}) {
        nodes {
          tableOfContents(maxDepth: 3)
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `);
    const articles = data.allMdx.nodes;
    return {
        general:   articles.filter((item) => item.fields.slug.includes('general')),
        primary:   articles.filter((item) => item.fields.slug.includes('primary')),
        secondary: articles.filter((item) => item.fields.slug.includes('secondary'))
    };
};
