import React           from 'react';
import { Link }        from 'gatsby';
import { Divider }     from '../../../../lib/components/divider/divider';
import { Title }       from '../../../../lib/components/title/title';
import { Wrapper }     from '../../../../lib/components/wrapper/wrapper.component';
import { cn }          from '../../../../lib/util/bem';
import { md }          from '../../../../lib/util/md';
import { getAllPosts } from './get-content';

const className = cn('content');

const Item = (props: {
    slug: string;
    title: string;
}) => (
    <Wrapper
        padding="md"
        margin={ { bottom: 'sm' } }
        hover={ { color: 'secondary' } }
        xsm={ { padding: 'xxs', margin: { bottom: 'off' }, color: 'key' } }
    >
        <Link
            className={ className('item') }
            to={ `${ props.slug.substring(1) }` }
            state={ {
                modal:    true,
                noScroll: true
            } }
            style={ { width: '100%' } }
        >
            <Wrapper>
                { /* <Divider margin="md"  /> */ }
                <Wrapper row>
                    <Title
                        size="h4"
                        xsm={ { size: 'h5' } }
                        margin="md"
                        color="key"
                        // xsm={{}}
                    >
                        { props.title.slice(0, 2) }
                    </Title>

                    <Title
                        size="h4"
                        xsm={ { size: 'h5' } }
                        // uppercase
                        margin="md"
                        hover={ { color: 'key' } }
                        style={ { fontWeight: 400 } }
                    >
                        { props.title.substring(3) }
                    </Title>

                </Wrapper>
            </Wrapper>
        </Link>
    </Wrapper>
);

export type ChapterType = 'secondary'|'general'|'primary'

export const ContentHandbook = (props: {year: string; chapter: ChapterType}) => {
    const content = getAllPosts();

    console.log(content);

    return (
        <Wrapper className={ className() }>
            { content[ props.chapter ].map((section, idx) => (
                <Wrapper key={ idx } frame="solid-grey" margin="md" radius="lg" padding="xxl" sm={ { padding: 'sm' } }>
                    { /* <Divider margin="md" color="key" /> */ }
                    <Title
                        size="h2"
                        margin="xl"
                        // uppercase
                        maxWidth="90rem"
                        sm={ { size: 'h4', margin: 'md' } }
                        hover={ { color: 'key' } }
                    >
                        <Link
                            className={ className('item') }
                            to={ section.fields.slug.substring(1) }
                            state={ {
                                modal:    true,
                                noScroll: true
                            } }
                            style={ { width: '100%' } }
                        >
                            { md(section.frontmatter.title) }
                        </Link>
                    </Title>

                    <Wrapper
                        row
                        styles={ { flexWrap: 'wrap' } }
                        columns={ 3 }
                        md={ { columns: 2 } }
                        sm={ { columns: 1 } }
                    >
                        { section.tableOfContents.items.map((heading, hindex) => {
                            if (heading.title) {
                                return (
                                    <Item
                                        key={ hindex }
                                        slug={ `${ section.fields.slug }${ heading.url }` }
                                        title={ heading.title }
                                    />
                                );
                            }
                            return null;
                        }) }
                    </Wrapper>

                </Wrapper>
            )) }
        </Wrapper>
    );

    // return (
    //     <Wrapper className={ className() }>
    //         { Object.keys(content).map((key, index) => (
    //             <Wrapper key={ index }>
    //                 <Title
    //                     size="h3"
    //                     type="chapter"
    //                     color={ t[ key ].color }
    //                     margin="xxl"
    //                     maxWidth="90rem"
    //                 >
    //                     { t[ key ].title }
    //                 </Title>
    //                 <Wrapper
    //                     row
    //                     styles={ { flexWrap: 'wrap' } }
    //                     columns={ 3 }
    //                     md={ { columns: 2 } }
    //                     sm={ { columns: 1 } }
    //                 >
    //                     { content[ key ].filter((item) => item.fields.slug.includes(props.year)).map((article, aIndex) => (
    //                         <Wrapper
    //                             key={ aIndex }
    //                             padding={ { right: 'xxl', bottom: 'xxl' } }
    //                             margin={ { bottom: 'sm' } }
    //                             hover={ { color: 'secondary' } }
    //                             xsm={ { padding: { right: 'off' }, margin: { bottom: 'xxs' }, color: 'key' } }
    //                         >
    //
    //                             <Link
    //                                 key={ aIndex }
    //                                 className={ className('item') }
    //                                 to={ `/annual-report${ article.fields.slug }` }
    //                                 state={ {
    //                                     modal:    true,
    //                                     noScroll: true
    //                                 } }
    //                                 style={ { width: '100%' } }
    //                             >
    //                                 <Wrapper>
    //                                     <Divider color={ t[ key ].color } margin="md" />
    //                                     <Wrapper row>
    //
    //                                         <Title
    //                                             size="h4"
    //                                             color={ t[ key ].color }
    //                                             uppercase
    //                                             margin="md"
    //                                         >
    //                                             { `${ index + 1 }.${ article.frontmatter.order }` }
    //                                         </Title>
    //                                         <Title
    //                                             size="h4"
    //                                             uppercase
    //                                             margin="xxs"
    //                                             hover={ { color: t[ key ].color } }
    //                                         >
    //                                             { article.frontmatter.title }
    //                                         </Title>
    //                                     </Wrapper>
    //                                 </Wrapper>
    //                             </Link>
    //                         </Wrapper>
    //                     )) }
    //                 </Wrapper>
    //             </Wrapper>
    //
    //         )) }
    //     </Wrapper>
    // );
};
