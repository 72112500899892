import React                            from 'react';
import { navigate }                     from 'gatsby';
import { SiteLayout }                   from 'lib/container/site-layout/site-layout';
import { Divider }                      from '../../../lib/components/divider/divider';
import { ParallaxImage }                from '../../../lib/components/parallax-image/parallax-image.component';
import { Title }                        from '../../../lib/components/title/title';
import { Wrapper }                      from '../../../lib/components/wrapper/wrapper.component';
import { TopBar }                       from '../../../lib/container/top-bar/top-bar';
import { useGetImgSet }                 from '../../../lib/util/get-images';
import { md }                           from '../../../lib/util/md';
import { ChapterType, ContentHandbook } from './data/content.component';

const HandbookSection = (props: {
    title: string;
    chapter: ChapterType;
}) => (
    <>
        <Divider margin="xxl" transparent />
        <Title color="key" size="h1" sm={ { size: 'h3', margin: 'off' } } type="chapter" margin="xxl" maxWidth="100rem" >
            { md(props.title) }
        </Title>
        <Divider margin="xxl" transparent />
        <ContentHandbook year="2021" chapter={ props.chapter } />
    </>
);
const AnnualReport2021 = () => {
    const img = useGetImgSet([ 'handbook_parent_student' ]);
    return (
        <SiteLayout>
            <TopBar onClick={ () => navigate('/') } />
            <Wrapper>
                <h1 style={ {
                    fontSize:      '10vw',
                    lineHeight:    1,
                    marginBottom:  '1rem',
                    maxWidth:      '100vw',
                    textTransform: 'uppercase'
                } }
                >
                    Parent,
                    <br />
                    Student Handbook
                    <em style={ { fontSize: '8vw' } }>2021 / 2022</em>
                </h1>
                <Wrapper
                    // styles={ { height: '120px' } }
                    flex={ 1 }
                    margin="xxl"
                />
                <ParallaxImage
                    src={ img.handbook_parent_student.src }
                    height={ 500 }
                    margin="xxl"
                />

                <HandbookSection
                    title="General Guidelines _for_ Parents And Students"
                    chapter="general"
                />

                <HandbookSection
                    title="Guidelines _For_ Primary Students And Parents"
                    chapter="primary"
                />

                <HandbookSection
                    title="Guidelines _For_ Secondary Students And Parents"
                    chapter="secondary"
                />

            </Wrapper>

        </SiteLayout>
    );
};
export default AnnualReport2021;
